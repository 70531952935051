import React from 'react';
import './App.css';
import { Route, BrowserRouter as Router, Routes, Navigate, useLocation } from 'react-router-dom';
import { DarkModeProvider } from './context/DarkModeContext/darkModeContext';
import Soon from './views/Soon/Soon';
import HeroSection from './views/HeroSection/HeroSection';
import Services from './views/Services/Services';
import About from './views/About/About';
import Contact from './views/Contact/Contact';
import Products from './views/Products/Products';
import ReactGA from 'react-ga4';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import "animate.css/animate.compat.css";
import Snowfall from 'react-snowfall';

// Import the Navbar component
import Navbar from './components/Navbar/Navbar';

function App() {
  ReactGA.initialize('G-8XLE5JQ8E9');
  ReactGA.send("pageview");

  return (
    <Router>
      {/*<Snowfall
        color="white"
        snowflakeCount={120}
        style={{
          position: 'fixed',
          zIndex: 9999,
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        snowflakeStyle={{
          opacity: 0.8,
          width: `${Math.random() * 5 + 2}px`,
          height: `${Math.random() * 5 + 2}px`,
        }}
      />*/}
      <DarkModeProvider>
        <ScrollToTop />
        {/* Use the AppContent component to handle routing */}
        <AppContent />
      </DarkModeProvider>
    </Router>
  );
}

function AppContent() {
  const location = useLocation();

  return (
    <>
      {/* Conditionally render Navbar, excluding the /soon route */}
      {location.pathname !== '/soon' && <Navbar />}
      <Routes>
        {/* Redirect the base route ("/") to "/soon" */}
        <Route path="/soon" element={<Soon />} />

        {/* You can keep the other routes for future use if needed */}
        {/*<Route path="/" element={<HeroSection />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<About />} />
        <Route path="/products" element={<Products />} />
        <Route path="/contact" element={<Contact />} />*/}

        {/* Redirect all other routes to "/soon" */}
        <Route path="*" element={<Navigate to="/soon" replace />} />
      </Routes>
    </>
  );
}

export default App;
