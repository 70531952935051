import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Soon.css';
import logo from './logo-new5.png';
import '@fortawesome/fontawesome-free/css/all.min.css';
import backgroundImage from './dark.png';
import 'animate.css';

function Soon() {
    const { t, i18n } = useTranslation();
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(false);

        try {
            const response = await fetch('https://formspree.io/f/xvgoogob', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                setSubmitted(true);
            } else {
                setError(true);
            }
        } catch (error) {
            setError(true);
        }
    };

    const toggleLanguage = () => {
        const newLang = i18n.language === 'en' ? 'pt' : 'en';
        i18n.changeLanguage(newLang);
    };

    return (
        <div className="soon-container" style={{ backgroundImage: `url(${backgroundImage})` }}>
            {/* Language Toggle Button */}
            <div className="language-toggle">
                <button onClick={toggleLanguage} className="lang-button">
                    {i18n.language.toUpperCase()}
                </button>
            </div>

            <div className="soon-header">
                <img src={logo} alt="Company Logo" className="soon-logo" />
                <h1 className="soon-title animate__animated animate__swing animate__infinite">
                    {t('soon.title')}
                </h1>
                <p className="soon-text">{t('soon.workingMessage')}</p>
                <p className="soon-text subtle-message">{t('soon.subtleMessage')}</p>
            </div>

            <div className="soon-content">
                {!submitted && <p className="subscribe-message">{t('soon.subscribeMessage')}</p>}

                {submitted ? (
                    <p className="success-message">{t('soon.successMessage')}</p>
                ) : (
                    <form onSubmit={handleSubmit} className="soon-form">
                        <input
                            type="email"
                            name="email"
                            className="soon-input"
                            placeholder={t('soon.emailPlaceholder')}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <button type="submit" className="soon-button">{t('soon.subscribeButton')}</button>
                    </form>
                )}

                {error && <p className="error-message">{t('soon.errorMessage')}</p>}
            </div>

            {/* Instagram icon and social media text */}
            <div className="social-follow">
                <p className="social-text">{t('soon.socialText')}</p>
                <a
                    href="https://www.instagram.com/nexgensolutions.pt/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="instagram-icon-link"
                >
                    <i className="fab fa-instagram instagram-icon"></i>
                </a>
            </div>
        </div>
    );
}

export default Soon;
